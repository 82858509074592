:root {
  --emerald-color: #145a6c;
  --orange-color: #f26448;
  --earth-color: #f2eae6;
  --earth-color-half: #f2eae680;
  --leaf-color: #05665f;
  --yellow-color: #ffd27a;
  --yellow-color-half: #ffd27a80;
  --green-color: #26b994;
  --green-color-half: #26b99480;
  --blue-color: #c0e1eb;
  --light-blue-color: #dff0f5;
  --blue-color-half: #c0e1eb80;
  --light-emerald-color: #f3f7f8;
  --emerald-color-half:  #145A6CE0;
  --emerald-color-quarter: rgba(20, 90, 108, 0.58);
  --success-color: #ecf7ed;
  --warning-color: #fff2d9;
  --error-color: #ff5e5b4d;
  --red-color: #e41919;
  --mint-color: #ebf2da;
  --dark-grey: #E0E7E7;
  
  --bg1: #ffffff;
  --bg2: #f7f7f5;
  --bg3: #f2f2f0;
  --bg4: #ebebe8;
  --bg5: #dedddb;

  --divider: var(--bg4);
  --border: var(--bg5);

  --text-main: #1c1a17;
  --text-secondary: #6c6c6c;

  --display-large: 72px;
  --display-medium: 56px;

  --heading-large: 44px;
  --heading-medium: 32px;

  --title-large: 28px;
  --title-medium: 24px;

  --text-large: 22px;
  --text-standard: 18px;
  --text-small: 16px;

  --margin-xlarge: 160px;
  --margin-large: 80px;
  --margin-medium: 40px;
  --margin-small: 20px;

  --padding-large: 48px;
  --padding-medium: 32px;
  --padding-small: 20px;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
