@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
@import url('/src/styles/style.variables.css');
@import url('/src/styles/globalFonts.css');

@font-face {
  font-family: 'Sofia Pro';
  src:
    local('Sofia Pro'),
    url('./fonts/sofiapro-regular-webfont.woff2') format('woff2');
}
@font-face {
  font-family: 'Sofia Pro Soft';
  src:
    local('Sofia Pro Soft'),
    url('./fonts/SofiaProSoftReg-webfont.woff2') format('woff2');
}
@font-face {
  font-family: 'Sofia Pro Medium';
  src:
    local('Sofia Pro Medium'),
    url('./fonts/sofiapro-medium-webfont.woff2') format('woff2');
}
@font-face {
  font-family: 'Sofia Pro Soft Medium';
  src:
    local('Sofia Pro Soft Medium'),
    url('./fonts/SofiaProSoftMed-webfont.woff2') format('woff2');
}
body {
  margin: 0;
  background: var(--bg1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
