.radioGroupContainer {
  display: flex;
  flex-wrap: nowrap;
  /* Enable wrapping for multiple rows */
  gap: 5px;

  /* Add spacing between radio options */
}

/* .radioGroup {
  max-width: 250px;
} */

.radioGroupContainer .questionDescription {
  margin-bottom: 3vh;
}

.radioLabel {
  display: flex;
  align-items: center;
  /* cursor: pointer; */
  flex-basis: 45%;
}

.radioInput {
  appearance: none;
  width: 158px;
  /* Set a minimum width */
  height: 44px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  white-space: nowrap;
}

.radioLabel-text {
  position: absolute;
  white-space: nowrap;
  /* Prevent the label from wrapping */
  margin-left: 20px;
}

.radioInput:disabled {
  cursor: default;
}

@media (max-width: 992px) {
  .radioGroupContainer {
    flex-wrap: wrap;
  }
}
