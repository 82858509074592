h1,h2,h3,p{
 color: var(--emerald-color);
}

.installer-container {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 40px;
    padding-top: 2rem;
    margin-bottom: 1rem;
}
.installer-container *{
    font-family: 'Sofia Pro';
}

.dot{
    width: 8px;
    height: 8px;
        border-radius: 50%;
                margin-right: 8px;
                background-color: var(--green-color);
}

@media (max-width: 992px) {
.installer-container {
        grid-template-columns: 100%;
        padding-top: 1rem;
    }
}
