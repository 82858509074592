.heading {
  font-family: Montserrat, sans-serif;
  font-size: 2.5rem;
  font-weight: Bold;
}

.paragraph {
  font-family: Montserrat, sans-serif;
}
.resident-info {
  margin: auto;
  max-width: 450px;
  margin-top: 20px;
}
