@import url('https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap');
* {
  box-sizing: border-box;
}
button,
a {
  cursor: pointer;
}

.menu-list-item a {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: relative;
}

.m

.menu-list-item a i {
  font-size: 1.375rem;
}

.css-1n0nbmo-MuiButtonBase-root-MuiMenuItem-root:hover {
  text-decoration: none;
  background-color: rgba(242, 101, 72, 0.3) !important;
}

.app-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: 0.2s;
  position: relative;
}

.app-content-wrapper {
  /* padding-bottom: 100px; */
  min-height: calc(100vh - 60px - 65px);
}
.profile-menu {
  position: relative;
}

.profile-menu-button {
  background: none;
  border: none;
  cursor: pointer;
}

.profile-menu-items {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 100;
}

.profile-menu-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.main-menu {
  display: flex;
}

.main-menu-item {
  margin-right: 10px;
  cursor: pointer;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  position: relative;
  background-color: white;
}
.app-header-container{
    display: grid;
      grid-template-columns: 1fr 4fr;
      width: 100%;
      gap: 40px;
}
.app-header-border-container {
  border-bottom: 1px solid #f2f2f0;
}

.header-banner {
  background-color: #f26548;
  height: 70px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.banner-text {
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.app-header-left,
.app-header-right {
  display: flex;
  align-items: center;
}

.app-header-right{
  justify-content: right;
}
.installer-breadcrumb{
  flex-grow: 1;
}
.app-header-left {
  flex-grow: 1;
}
.app-header-right-cotainer {
  display: flex;
}

.app-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  background: #f7f7f5;
  height: 100%;
  /* position: absolute; */
  bottom: 0;
  /* margin-top: 60px; */
}
.app-footer-left,
.app-footer-right {
  display: flex;
  align-items: center;
}
.app-footer a {
  text-decoration: underline;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
}
.app-footer p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6c6c6c;
  margin: 0;
}
.logo {
  margin-top: 10px;
  cursor: pointer;
}
.footer-logo {
}
.profile-btn {
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--yellow-color);
  font-size: 15px;
  font-weight:400;
  color: var(--emerald-color);
  font-family: 'Sofia Pro Soft';

}
.profile-btn img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 4px;
}
.profile-btn span {
  color: #1f1c2e;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.page-content {
  flex: 1;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .profile-btn span {
    display: none;
  }
  .app-header-right button {
    margin-left: 4px;
  }

  .app-footer {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 575px) {
  .app-icon {
    display: none;
  }

  .app-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-input {
    max-width: 120px;
    font-size: 14px;
  }

  .profile-btn img {
    width: 24px;
    height: 24px;
  }
  .search-wrapper {
    display: none;
  }
}
