.installer-sidebar *{
 font-weight: 700 ;
 font-size: 18px;
color: var(--emerald-color);
}
.installer-sidebar .main-sidebar li > a{
    text-decoration: none;
        display: flex;
            align-items: center;
            gap: 0.5rem;
            height: 50px;
            padding: 0 16px ;
            border-radius: 8px;
            position: relative;
        
  }

  .installer-sidebar .top-right{
    position: absolute;
    top: -7px;
    right: -14px;
  }


.installer-sidebar .main-sidebar{
    margin-right: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 206px;

}
.installer-container .installer-tab-slider {
    position: fixed;
    top: 0;
    background: white;
    height: 100vh;
    padding: 8px;
    width: 80vw;
    max-width: 550px;
    right: -100%;
    transition: right 0.3s ease-in-out;
        /* Start off-screen to the left */
}

.installer-container .installer-tab-slider.open {
    display: flex;
    z-index: 1;
    right: 0%;
}

.close-grey-button {
    padding: 15px;
    background-color: var(--bg2);
    display: inline;
    border: none;
    border-radius: 8px;
}
.slider-active-hamburger {
    background-color: var(--bg2);
    padding: 10px 20px;
    border-radius: 8px;
}
.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--emerald-color-half);
    z-index: 1;
}
.desktop-sidebar{
    height: 100%;
    min-height: calc(100vh - 60px - 64px - 3rem);
}
@media (max-width: 992px) {
    .installer-container {
        grid-template-columns: auto;
        padding-top: 1rem;
    }
.desktop-sidebar {
        height: calc(100vh - 60px - 64px - 1rem);
}
}