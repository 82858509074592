.input-field {
  padding: 7px 15px;
  width: 100%;
  outline: 0;
  background: white;
  border: 1px solid var(--bg5);
  border-radius: 4px;
}

.input-field:focus {
  border: 1px solid var(--emerald-color);
}

.input-field:disabled {
  opacity: 0.5;
}

.input-field:placeholder-shown + .clearText {
  display: none;
}

.show-password-text {
  display: inline-block;
  position: absolute;
  top: 4px;
  right: 45px;
}

.clearText {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 12px;
}

.fieldSet {
  position: relative;
}

.text-field-container {
  margin-bottom: 10px;
}
