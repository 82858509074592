.custom-form {
  display: grid;
  gap: 1.5rem;
  margin-top: 1rem;
  width: 400px;
}

.address_manual {
  margin-top: 10px;
  text-decoration: underline;
  margin-bottom: 5vh;
}
