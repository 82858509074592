.form-style {
  display: grid;
  gap: 1.5rem;
  margin-top: 1rem;
}

.name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
