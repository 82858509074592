.box-container {
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
}

.box-item {
  border-radius: 8px;
  background-color: var(--bg3);
  box-shadow: 0 1px 2px 1px var(--bg4);
  display: inline-flex;
  min-width: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
