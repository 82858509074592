.card-container {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: center;
  border-radius: 6px;
  margin: calc(100vw * 0.25 / 27);
  padding: calc(100vw * 0.5 / 27);
  background-color: #ffffff;
  z-index: 1;
  flex-grow: 1;
  background-color: var(--bg3);
  box-shadow: 3px 3px 10px rgb(0 0 0 / 0.2);
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

.card-container:hover {
  background-color: var(--blue-color);
}

.card-image {
  display: flex;
  justify-content: center;
  height: 100px;
  width: auto;
}
.card-text {
  text-align: center;
}
.option-heading {
  margin: 0;
  margin-top: 5px;
  font-size: 20px;
}
.option-heading-mini {
  margin: 0;
}

/*
.card-container:hover h1 {
    color: #FFFFFF;
}

.card-container:hover div {
    color: #FFFFFF;
}
*/

@media (max-width: 856px) {
  .card-container {
    flex-flow: row;
    justify-content: start;
    align-items: center;
    text-align: left;
  }
  .card-text {
    margin-left: 20px;
    text-align: left;
  }
  .questionContainer {
    padding-right: 15px;
    padding-left: 15px;
  }
}
