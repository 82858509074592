.monthInputContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4vh;
}

.inputField {
  position: relative;
  width: 250px; /* Adjust the width as per your requirements */
  margin: 0 10px 5px 0; /* Add some spacing between the input fields */
}

.cancelIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

@media (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .monthInputContainer {
    flex-direction: column; /* Stack input fields vertically on small screens */
  }

  .inputField {
    margin-right: 0;
    width: 90%; /* Remove right margin to stack input fields vertically */
  }
}
