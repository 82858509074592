.alert-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;
  border-radius: 6px;
  width: fit-content;
  margin-bottom: 20px;
}
.alert-container p {
  margin: 0px;
}

.text-container {
  margin-left: 10px;
}
