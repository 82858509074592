.auth-container {
  display: flex;
  justify-content: start;
  flex-direction: row-reverse;
  height: calc(100vh - 64px) !important;
  gap: 4rem;
}

.auth .app-header {
  border-bottom: 1px solid #f2f2f0;
}

.auth-box {
  position: relative;
  text-align: center;
}

.auth-form .InputContainer {
  margin-top: 0;
}

.auth-form .text-field-container {
  margin-bottom: 0;
}

.auth-image {
  max-width: 563px;
  max-height: 693px;
  width: 100%;
}

.auth-box img {
  align-self: center;
  max-width: 150px;
  object-fit: cover;
}

.auth-form {
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.auth-form .undefined .flex-row-wrap {
  justify-content: start !important;
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo_Container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.auth-social-button {
  margin-top: 20px;
  margin-bottom: 40px;
}

.auth-register-list {
  justify-content: space-between;
}

.auth-register-list,
.auth-register-list-item {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

@media (max-width: 768px) {
  .auth-container {
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    padding-bottom: 2rem;
  }

  .auth-container > div {
    margin: 0 5px;
  }

  .auth-form {
    margin: 30px 0;
  }

  .auth-text {
    margin-top: 20px;
  }

  .auth-register-list-item:last-child {
    display: none;
  }
}
