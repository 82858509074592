.photo-container {
  margin-bottom: var(--margin-small);
  position: relative;
}

.photo-field {
  cursor: pointer;
  min-height: 100px;
  min-width: 270px;
  height: fit-content;
  width: fit-content;
  display: grid;
  border-radius: 6px;
  border: 1px solid var(--emerald-color);
  gap: 10px; /* Add spacing between images */
}

.photo {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.photo img {
  width: 90px;
  height: 90px;
  display: block;
}

.image-list {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

/* Style for each image item */
.image-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.image-item img {
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}

.cross-button {
  color: var(--emerald-color);
  cursor: pointer;
}

.photo-container p {
  margin: 0;
}
.photo-button {
  display: flex;
  align-items: center;
  height: 30px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  color: var(--bg1);
  border: 1px solid var(--emerald-color);
  background-color: var(--emerald-color);
}
.upload-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}
.photo-camera-button {
  display: flex;
  align-items: center;
}
/* Style for the disabled component */
.photo-container.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.photo-container.disabled button {
  cursor: not-allowed;
}
