.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.hamburger .bar {
  width: 100%;
  height: 2px;
  background-color: var(--emerald-color);
  transition: 0.3s;
}

/* .hamburger.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger.open .bar:nth-child(2) {
    opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
} */
