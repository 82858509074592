.nativeRadioGroup {
  margin-bottom: 30px;
  max-width: 800px;
}
.radioGroupContainer .questionDescription {
  margin-bottom: 3vh;
}

.nativeRadioInput {
  appearance: none;
  width: 18px; /* Set a minimum width */
  height: 18px;
  background-color: var(--earth-color); /* Set the background color to transparent */
  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  white-space: nowrap; /* Prevent text wrapping */
}
.nativeRadioLabel-text {
  /* position: absolute; */
  white-space: nowrap; /* Prevent the label from wrapping */
  margin-left: 10px;
}

@media (min-width: 768px) {
  .radioGroupContainer {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .radioLabel {
    flex-basis: calc(33.33% - 10px);
  }
}
