.notification-center {
  border-radius: 8px;
  background: var(--bg2);
  border: var(--border) 1px solid;
}

.notification-center-header {
  padding: 8px;
  align-items: center;
  border-radius: 8px 8px 0 0;
}
