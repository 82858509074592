.InputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.InputLabel {
  margin-bottom: 10px;
}

.questionDescription {
  margin-bottom: 10px;
}

.inputHelperIcon {
  margin-left: 10px;
}

.label-gap {
  margin-bottom: 2rem;
}
