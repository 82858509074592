.card-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px calc(-100vw * 0.25 / 27);
  animation: fadeIn 0.3s ease-in both;
}

.standard-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.mini-container {
  display: flex;
  flex: 50%;
  flex-wrap: wrap;
  margin: 20px 0;
}

.mini-card-image-container {
  flex-shrink: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.mini-card-wrapper {
  display: flex;
  gap: 20px;
}

.mini-container .card-container {
  justify-content: center;
}

.image-style {
  height: 300px;
}

@media (min-width: 1343px) {
  .image-style {
    height: 400px;
  }
  .mini-card-container {
    gap: 60px;
  }
}

@media (min-width: 1200px) and (max-width: 1342px) {
  .mini-card-container {
    gap: 60px;
  }
  .mini-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mini-card-container {
    gap: 60px;
  }
  .mini-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .image-style {
    height: 200px;
  }
  .mini-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .image-style {
    height: 200px;
  }
  .mini-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
  .mini-card-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 575px) {
  .image-style {
    height: 150px;
  }
  .mini-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
  .mini-card-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
}
