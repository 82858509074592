/* General */
.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.align-center {
  align-content: center;
}

.align-item-center {
  align-items: center;
}

.align-item-end {
  align-items: end;
}

.align-self {
  align-self: center;
}

.align-self-end {
  align-self: end;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}
.gap-half {
  gap: 0.5rem !important;
}

.gap-1 {
  gap: 1rem !important;
}

.gap-2 {
  gap: 2rem !important;
}

.gap-3 {
  gap: 3rem !important;
}

.gap-4 {
  gap: 4rem !important;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

/* Grid */
.grid {
  display: grid;
  gap: 1rem;
}

.grid-center {
  display: grid;
  gap: 1rem;
  align-content: center;
  justify-content: center;
}

.grid-cols-2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.grid-cols-3 {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
}

.grid-equal-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.grid-split-2 {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 1rem;
}

@media (max-width: 991px) {
  .grid-equal-2,
  .grid-split-2 {
    display: inline-block;
  }

  .grid-cols-3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 576px) {
  .grid-cols-3 {
    display: inline-block;
  }
}

/* Flex */
.flex-1 {
  flex: 1;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-cols-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flex-cols-nowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-card {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: flex-start;
}
.flex-row-tab-col {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: start;
}

.flex-space-between {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

/* Containers */
/* .page-header {
  border-radius: 24px;
  padding: 60px 0;
} */

.rounded-border{
  border-radius: 8px ;
}
.rounded-border-lg {
 border-radius: 16px;
}
.container-wide {
  max-width: 1400px;
  margin: 0 auto;
  width: calc(100vw - 40px);
}

.container-narrow {
  max-width: 680px;
}

.container-bootstrap {
  max-width: 1170px;
  height: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
/* margins */
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.resident-container {
  padding: 3rem 0;
}
.page-header {
  border-radius: 24px;
  padding: 60px 0;
}
@media (min-width: 576px) {
  .container-wide {
    width: calc(100vw - 80px);
  }

  .container-bootstrap {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-bootstrap {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-bootstrap {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-bootstrap {
    max-width: 1140px;
  }
}

@media (max-width: 768px) {
  .d-mob-none {
    display: none;
  }
}
@media (max-width: 992px) {
  .d-tab-none {
    display: none;
  }
  .flex-row-tab-col {
    flex-direction: column;
  }
}

.header-image {
  max-width: 150px;
}

.onhover-pointer:hover {
  cursor: pointer;
}

.underlined {
  text-decoration: underline;
}

button.move-icon-right:hover .btn-icon {
  transform: translateX(5px);
}
button.move-icon-right .btn-icon {
  transition: transform 0.3s 0.1s ease;
  -moz-transition: transform 0.3s 0.1s ease;
  -webkit-transition: transform 0.3s 0.1s ease;
}
