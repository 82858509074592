.checkbox-label a {
  color: white;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.checkbox-label {
  margin-left: 5px;
}

.checkbox-input {
  display: none;
}

.checkbox-custom {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid white;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  align-self: flex-start;
}

.checkbox-custom::before {
  content: '\2713';
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.checkbox-input:checked + .checkbox-custom::before {
  opacity: 1;
}

.checkbox-input:checked + .checkbox-custom {
  background-color: var(--emerald-color) !important;
}

.checkbox-input:focus + .checkbox-custom {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
}
.checkbox p {
  margin: 0;
}
.dialog-action-buttons {
  display: flex;
  flex-direction: row;
}
@media (max-width: 991px) {
  .dialog-action-buttons {
    display: contents !important;
  }
}
