.card-radioInput {
  appearance: none;
  width: 158px;
  height: 43px;
  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  white-space: nowrap;
}

.radio-card {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  border-radius: 6px;
  margin: calc(100vw * 0.25 / 27);
  padding: calc(100vw * 0.5 / 27);
  z-index: 1;
  flex-grow: 1;
  background-color: var(--earth-color);
  box-shadow: 3px 3px 10px rgb(0 0 0 / 0.2);
  transition: background-color 0.3s ease-out;
}

.radio-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 7px;
}
.radio-card-option-image {
  height: 125px;
  margin-bottom: 10px;
}
