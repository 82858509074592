@import url('/src/styles/style.variables.css');

.btn {
  padding: 14px 32px;
  letter-spacing: 1.5px;
  margin: 1rem auto;
  min-width: 180px;
  cursor: pointer;
  border-radius: 100px;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.btn-small {
  padding: 10px 20px;
  letter-spacing: 1px;
  margin: 10px 0;
  min-width: 130px;
  cursor: pointer;
  border-radius: 50px;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.btn-label {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
}

.primary {
  border: 1px solid var(--emerald-color);
  background-color: var(--emerald-color);
  color: var(--bg1) !important;
}
.primary:hover {
  border: 1px solid rgb(21 90 108 / 0.8);
  background-color: rgb(21 90 108 / 0.8);
}
.secondary:hover {
  color: var(--bg1);
}
.secondary {
  border: 1px solid var(--orange-color);
  background-color: var(--orange-color);
  color: var(--bg1);
}
.secondary-border {
  border: 1px solid var(--orange-color);
  color: var(--orange-color);
  background-color: var(--bg1);
}
.secondary-border-trans-bg {
  border: 1px solid var(--orange-color);
  color: var(--orange-color);
  background-color: transparent;
  font-weight: 700;
}
.secondary-border-trans-bg:hover {
  border: 1px solid var(--orange-color);
  background-color: var(--orange-color);
  color: var(--bg1);
}
.primary-border {
  border: 1px solid var(--emerald-color);
  color: var(--emerald-color);
  background-color: var(--bg1);
}
.primary-border:hover {
  border: 1px solid var(--emerald-color);
  background-color: var(--emerald-color);
  color: var(--bg1);
}

.primary-border-trans-bg {
  border: 1px solid var(--emerald-color);
  color: var(--emerald-color);
  background-color: transparent;
  font-weight: 700;
}

.primary-border-trans-bg:hover {
  border: 1px solid var(--emerald-color);
  background-color: var(--emerald-color);
  color: var(--bg1);
}
.secondary:hover {
  border: 1px solid rgb(242 101 72 / 0.8);
  background-color: rgb(242 101 72 / 0.8);
}

.white {
  border: 1px solid var(--border);
  color: var(--text-main);
  background-color: var(--bg1);
}
.white:hover {
  border: 1px solid var(--border);
  background-color: rgb(247 247 245 / 0.8);
  box-shadow: rgb(255 255 255 / 24%) 0px 8px 16px 0px;
}
.green {
  border: 1px solid var(--green-color);
  background-color: var(--green-color);
  color: var(--bg1);
}
.green:hover {
  border: 1px solid rgba(38, 185, 148, 0.8);
  background-color: rgba(38, 185, 148, 0.8);
}

.btn:disabled,
.btn-small:disabled,
.primary:disabled,
.secondary:disabled,
.white:disabled,
.green:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
